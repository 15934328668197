require('../css/app.css');

// loads the jquery package from node_modules
const $ = require('jquery');

 // create global $ and jQuery variables
global.$ = global.jQuery = $;

require('bootstrap');
require('select2');
require('select2/dist/css/select2.css');
require('select2-bootstrap4-theme/dist/select2-bootstrap4.css');
$('select').select2({
    theme: 'bootstrap4',
});

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});
